import { NavigationContainer } from "@react-navigation/native";
import MyTabCall from "./MyTabs";
//import { NavigationContainer } from '@react-navigation/native';
//import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';


export default function App(){
  return(
   <NavigationContainer>
    <MyTabCall/>
    </NavigationContainer>
  );
}