import React, {useState} from 'react';
import {Alert, Modal, StyleSheet, Text, Pressable, View} from 'react-native';

const  FUNMODEL = () => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <View>
            <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        statusBarTranslucent={false}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
        <View style ={styles.centeredView}>
        <View style ={styles.centeredView}>
            <Text style={styles.modalText}>{'\n'}Foot reflexology is the art of applying pressure at vital points. {'\n'}By applying pressure, the nerve ending on the foot are activated{'\n'} and it cures many ailments like knee,neck,back pain and relaxes the body totally.</Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => setModalVisible(!modalVisible)}>
              <Text alignItems='center' >CLOSE</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
      <Pressable
        style={[styles.button, styles.buttonOpen]}
        onPress={() => setModalVisible(true)}> 
        <Text>READ MORE...</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'right',
    alignItems: 'right',
    marginTop: 22,
    padding:140,
    width:400,
   
  },
  modalView: {
    margin: 20,
    height:130,
    width:200,
    backgroundColor: 'goldenrod',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: 'teal',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: 'teal',
  },
  buttonClose: {
    backgroundColor: 'teal',
  },
  textStyle: {
    color: 'teal',
    backgroundColor:'teal',
    fontWeight: 'bold',
    textAlign: 'center',
    height:210,
    weight:200
  },
  modalText: {
    marginBottom: 15,
    height:220,
    width:200,
    backgroundColor:'lightcoral',
    textAlign: 'center',
    fontFamily:'Courier',
    fontWeight: 'bold',
    fontSize:'50'
  },
});

export default FUNMODEL;