import React, { useState } from 'react';
import { Text, View, Image, TouchableOpacity, Linking, StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import MyTabsOnlineServicesCall from './MyTabsOnlineServices';
import MyTabsOfflineServicesCall from './MyTabsOfflineServices';
import MyTabsOneonOneServicesCall from './MyTabsOneOnOneServices';
import MyTabsRecordedVideoServicesCall from './MyTabsRecordedVideos';
import MyTabsOtherServicesCall from './MyTabsOtherServices';
import MyTabsTestimonialsCall from './MyTabsTestimonials';
import MyTabsLocationsCall from './MyTabsLocations';
import MyTabsTeamCall from './MyTabsTeam';
import MyTabsHomeCall from './MyTabsHome';

const MyTaby = createMaterialTopTabNavigator();

function MyTabCall() {
  const [mobileNumber, setMobileNumber] = useState('9499010610');
  const [whatsAppMsg, setWhatsAppMsg] = useState('hi from sai siddha yogashram website - enquiry');

  const HandleWhatsappSMS = () => {
    if (mobileNumber.length !== 10) {
      alert('Please insert correct contact number');
      return;
    }
    Linking.openURL('https://api.whatsapp.com/send?phone=91' + mobileNumber + '&text=Hi Saisiddhasamathiyogashram.com. This is an enquiry message')
      .then((data) => {
        console.log('WhatsApp Opened');
      })
      .catch(() => {
        alert('Make sure Whatsapp is installed on your device');
      });
  };

  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      {/* Header */}
      <View style={[styles.header, { backgroundColor: 'purple' }]}>
        <Image style={styles.logo} source={require('./Pictures/saibaba1.jpeg')} />
        <View style={styles.headerContent}>
      
          <TouchableOpacity onPress={() => navigation.navigate('Home')}>
            <Image style={styles.lotusLogo} source={require('./Pictures/lotusyogalogo1.png')} />
          </TouchableOpacity>
          <Text style={styles.headerText}>{'\t'}SAI SIDDHA SAMATHI YOGASHRAM</Text>
        </View>
        <View style={styles.contactInfo}>
          <View style={styles.phoneNumbers}>
            <Text style={styles.contactText}>Contact No: 7401598515</Text>
            <TouchableOpacity style={styles.whatsappContainer} onPress={HandleWhatsappSMS}>
              <Image style={styles.whatsappIcon} source={require('./Pictures/whatsapp-transparent.png')} />
              <Text style={styles.contactText}>WhatsApp No: 9499010610</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      {/* Tab Navigator */}
      <MyTaby.Navigator
        screenOptions={{
          tabBarActiveTintColor: 'indigo',
          tabBarInactiveTintColor: 'black',
          tabBarLabelStyle: { textAlign: 'center', fontSize: 15, fontFamily: 'Courier-Bold' },
          tabBarIndicatorStyle: {
            borderBottomColor: '#C2D5A8',
            borderBottomWidth: 2,
            fontWeight: 'bold',
            fontSize: 200,
          },
          tabBarStyle: { backgroundColor: '#b8860b' },
        }}
        initialRouteName="Home"
        activeColor="#ff8c00"
        labelStyle={{ fontSize: 40, fontWeight: 'bold', fontFamily: 'Times-Italic' }}
        style={{ backgroundColor: '#f0e68c' }}>
        <MyTaby.Screen name="Home" component={MyTabsHomeCall} options={({ navigation }) => ({
          tabBarLabel: "HOME",
          fontWeight: "bold",
          fontFamily: "Courier-Bold",
          style: {
            backgroundColor: "#21147a"
          }
        })} />
        <MyTaby.Screen name="Online Services" fontWeight='bold' component={MyTabsOnlineServicesCall} />
        <MyTaby.Screen name="Offline Services at Our Studio" fontWeight='bold' component={MyTabsOfflineServicesCall} />
        <MyTaby.Screen name="One on One Services" fontWeight='bold' component={MyTabsOneonOneServicesCall} />
        <MyTaby.Screen name="Recorded Video Services" fontWeight='bold' component={MyTabsRecordedVideoServicesCall} />
        <MyTaby.Screen name="Other Services" component={MyTabsOtherServicesCall} />
        <MyTaby.Screen name="Team" component={MyTabsTeamCall} />
        <MyTaby.Screen name="Testimonials" fontWeight='bold' component={MyTabsTestimonialsCall} />
        <MyTaby.Screen name="Studio Locations" fontWeight='bold' component={MyTabsLocationsCall} />
      </MyTaby.Navigator>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'purple', // Set the background color of the entire banner
  },
  header: {
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%', // Ensure the header takes full width
  },
  logo: {
    height: 100,
    width: 100,
    resizeMode: 'contain',
    marginRight: 10,
  },
  headerContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight:40,
  },
  lotusLogo: {
    height: 70,
    width: 60,
    resizeMode: 'contain',
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    marginTop: 10,
  },
  contactInfo: {
    alignItems: 'flex-end',
  },
  phoneNumbers: {
    alignItems: 'flex-end', // Align the phone numbers to the right
  },
  contactText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 5,
    marginLeft: 5,
  },
  whatsappContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  whatsappIcon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    marginRight: 5,
  },
});

export default MyTabCall;
