import React, { useState, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity,ScrollView } from 'react-native';
import { Video } from 'expo-av';
import { Ionicons } from '@expo/vector-icons'; 
import { useIsFocused } from '@react-navigation/native';
//import { ScrollView } from 'react-native-gesture-handler';


export default function MyTabsTestimonialsCall() {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const videoRefs = useRef([]);

  const data = [
    // Your video data array
    {
      id: 'Anand - Partner in Aravind Petroleum',
      video1: require('./Pictures/Feedback-Anand-Sir-Resized8.mp4'),
    },
    {
      id: 'Poornima Shankar - NGO Volunteer',
      video1:   require('./Pictures/Feedback-Poornima-Resized3.mp4'),
     },
     {
      id: 'Divya  Kode - Entrepreneur',
      video1:   require('./Pictures/Feedback_Divya-Mam-Resized4.mp4'),
     },
   
     {
      id: 'Anuradha - Media Personality',
      video1:   require('./Pictures/Feedback-Anuradha-Resized9.mp4'),
     },
     {
      id: 'Adithya Shanmugam - Banker',
      video1:   require('./Pictures/Feedback-Adi-Resized.mp4'),
     },
     {
      id: 'Keerthi Vasan - Rgnl Dir - EFF Ltd.',
      video1:   require('./Pictures/Feedback_KeerthiVasan-Resized1.mp4'),
     },
     {
      id: 'Indu Shanmugam - Architect',
      video1:   require('./Pictures/Feedback-Indu-Resized1.mp4'),
     },
     {
      id: 'Mohan Alse - Stock Market Expert',
      video1:   require('./Pictures/Feedback-Mohan-Resized.mp4'),
     },
     {
      id: 'Lalitha Suresh - Teacher',
      video1:   require('./Pictures/Feedback-Lalitha-Resized.mp4'),
     },
    
     
     {
      id: 'Hemalatha Seshadri - Teacher',
      video1:   require('./Pictures/Feedback-Hema-Resized.mp4'),
     },
     {
      id: 'Vijayalakshmi - Music Teacher',
      video1:   require('./Pictures/Feedback-Viji-Resized2.mp4'),
     },
    // Add other video data objects here

  ];

  const handleTogglePlayback = (index) => {
    // Pause the currently playing video, if any
    if (selectedVideoIndex !== null && selectedVideoIndex !== index) {
      videoRefs.current[selectedVideoIndex].pauseAsync();
    }

    // Toggle playback of the selected video
    setSelectedVideoIndex(selectedVideoIndex === index ? null : index);
    if (selectedVideoIndex === index) {
      videoRefs.current[index].pauseAsync();
    } else {
      videoRefs.current[index].playAsync();
    }
  }

  return (
    <View style={{ backgroundColor: 'bisque', flex: 1 }}>
      <Text style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>TESTIMONIALS OF STUDENTS</Text>
      <View style={styles.container}>
        {data.map((item, index) => (
          <View key={item.id} style={styles.item}>
            <Text style={styles.title}>{item.id}</Text>
            <Video
              ref={(ref) => (videoRefs.current[index] = ref)}
              source={item.video1}
              style={styles.video}
              resizeMode="cover"
              useNativeControls={false}
              isLooping
              shouldPlay={selectedVideoIndex === index}
            />
            <View style={styles.controls}>
              <TouchableOpacity onPress={() => handleTogglePlayback(index)}>
                <Ionicons name={selectedVideoIndex === index ? "pause-circle" : "play-circle"} size={36} color="#4287f5" />
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 2,
    flexDirection: 'row',
    flexWrap:'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
    backgroundColor: 'bisque'
  },
  scrollContent: {
    justifyContent: 'center',
    flex:1, 
    alignItems: 'center',
  },
  item: {
    width: '48%', // Adjust width as per your design
    margin: 8,
    marginBottom: 4, // Reduce the marginBottom to decrease the gap between rows
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    padding: 10,
    backgroundColor: '#f9f9f9',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  video: {
    width: 400,
    height: 305,
    borderRadius: 8,
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
});
