import React, { useState, useRef,useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Video } from 'expo-av';
import { Ionicons } from '@expo/vector-icons'; 
//import { useIsFocused } from '@react-navigation/native'; // Import useIsFocused hook


const data = [
  {
    id: 1,
    video1: require('./Pictures/Best-Asanas-Healthy-life.mp4')  // Ensure the correct file path
  },
];

const MyTabsRecordedVideoServicesCall = () => {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const videoRefs = useRef([]);
 


 /* useEffect(() => {
    // Pause the video when the screen loses focus
    if (!useIsFocused && selectedVideoIndex !== null) {
      videoRefs.current[selectedVideoIndex].pauseAsync();
    }
  }, [useIsFocused, selectedVideoIndex]);
*/
  const handleTogglePlayback = (index) => {
    if (selectedVideoIndex === index) {
      setSelectedVideoIndex(null);
      videoRefs.current[index].pauseAsync();
    } else {
      setSelectedVideoIndex(index);
      videoRefs.current[index].playAsync();
    }
  }; 




  /*
//for pausing the running video 
const VideoScreen = () => {
  const [isPlaying, setIsPlaying] = useState(true); // Manage the playing status of the video

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsPlaying(false); // Pause the video when the screen loses focus
    });

    return unsubscribe;
  }, [navigation]);

//for pausing the running video 
  */
 

const [isPlaying, setIsPlaying] = useState(true); // Manage the playing status of the video


  return (
    


<View style ={{backgroundColor: 'bisque',flex:1,alignContent:'center'}}>
    <Text  style={{ fontSize: 18, fontWeight: 'bold',  textAlign: 'center', }}>RECORDED VIDEO SESSIONS</Text>
    <Text  style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>AVAILABLE FOR FOLLOW UP SESSIONS ONLY</Text>
    <Text  style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>(SAMPLE VIDEO BELOW)</Text>
      {data.map((item, index) => (
        <View  style={{backgroundColor: 'bisque'}}  key={item.id} >
        <View key={item.id} style={{flexDirection:'row',alignContent:'center',justifyContent:'center'}} >
          <Video
          key={item.id} // Assign a unique key to each Video component
            ref={(ref) => (videoRefs.current[index] = ref)}
            source={item.video1}
            style={styles.video}
            resizeMode="cover"
            
            useNativeControls={false}
            isLooping
            shouldPlay={selectedVideoIndex === index}
          />
          </View>
          <View  style={{flexDirection:'row',alignContent:'center',justifyContent:'center'}} key={'controls-${item.id}'}>
            <TouchableOpacity onPress={() => handleTogglePlayback(index)}>
              <Ionicons name={selectedVideoIndex === index ? "pause-circle" : "play-circle"} size={36} color="#4287f5" />
            </TouchableOpacity>
          </View>
          <Text></Text>
          <Text  style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>Recorded Video Service - INR 1600.00 per month</Text>
          <Text></Text>
   
        
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    
  justifyContent:'center',
   alignContent:'center',
    backgroundColor: 'bisque',
    flexDirection:'row'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent:'center',
    alignContent:'center',
    
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
 
    justifyContent:'center',
    alignContent:'center',
  },
  item: {

    justifyContent:'center',
    alignItems:'stretch',
    alignContent:'center',
    margin: 8,
    height: 1200,
    width:750,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    
  },
  itemTitle: {
    fontSize: 16,
    fontWeight: 'bold',
   
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center'
  }, 
  video: {
    width: 1200,
    height: 750,
    borderRadius: 8,
    alignContent:'center',
    justifyContent:'center',
  },
  controls: {
    flexDirection: 'row',
    
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    
  },
});

export default MyTabsRecordedVideoServicesCall;
