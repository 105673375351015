import { Text, View,Image,FlatList,StyleSheet,ScrollView} from 'react-native';
import FUNMODEL from './SampleModel';



const data = [
  {
    id: 'FOOT REFLEXOLOGY',
    img: require('./Pictures/footreflexology.jpg'),
    desc: "Foot reflexology is the science of activating the vital pressure point on the foot by applying gentle strokes through fingers. It helps in relieving muscles stress and aids in sleeping",
 fee:" INR 1000.00 per session"
  },
  {
    id: 'THERAPEUTIC MASSAGE',
    img: require('./Pictures/TherapticMaasage.jpeg'),
    desc: "Therapautic massage mobilises soft tissues for inducing relaxation. It helps in reducing pain in specific ailments like neck/shoulder/back/knee pain" ,
 fee:"INR 1200.00 per session "
  },

  {
    id: "ACUPUNCTURE AND  SEED THERAPY",
    img: require('./Pictures/acupunctureSeedTherapy-New1.jpg'),
    desc: "Acupuncture is inserting needles on the skin at specific lines of energy. Seed therapy is ancient system of healing by sticking native seeds in fingers",
  fee:"INR 300.00 per sitting"
  },

  {
    id: "CUPPING THERAPY ",
    img:require('./Pictures/cupping.jpg'),
    desc: "Cupping is applying hot or cold vacuum cups in secific areas of the skin  to activate metabolism and immune system",
    fee: "INR 500.00 per sitting"
  },

    // Add more dummy data as needed
];


  const renderItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={{ fontSize: 15, fontWeight: 'bold', flex: 1, textAlign: 'left', }}>{item.id}</Text>
     

      
      <Image style={{height:250,width:350,marginRight:5}} source={item.img}></Image>
      <Text style={{ fontSize: 14, fontWeight: 'bold', flex: 1, textAlign: 'left', }}>{item.desc}</Text>
     
      <Text style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left',borderWidth: 3,
    borderColor: "brown",
    borderRadius: 20, }}>{item.fee}</Text>
      
    </View>
  );



function MyTabsOtherServicesCall() {


  return (
    <ScrollView style ={{backgroundColor: 'bisque' , height:100}}>
      <Text  style={{ fontSize: 18, fontWeight: 'bold', flex: 1, textAlign: 'center', }}>{'\n'}OTHER SERVICES{'\n'}{'\n'}</Text>
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      numColumns={2}
    />
    </ScrollView>
  );
};


const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 420,
    width: 400,
    borderWidth: 2,
    borderColor: '#ddd',
    borderRadius: 8,
    
  },
});





  export default MyTabsOtherServicesCall;