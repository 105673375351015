import { Text, View,Image,ScrollView,StyleSheet,FlatList} from 'react-native';

const data = [
  {
    
    id1: 'BEGINNER COURSE-1 MONTH',
    id2: 'Aims to bring flexibility and trains towards Surya Namaskaram and Pranayama',
    id3: 'Course Fee INR:500.00 per session',
   img: require('./Pictures/One-On-OneBeginner.jpeg') 
  },
  {
  id1: 'FOLLOW UP CLASSES',
  id2: 'Surya Namaskaram,,Pranayama and Aasanas',
  id3: 'Course Fee INR:500.00 per session',
  img: require('./Pictures/One-On-OneFollowUps1.jpg') 
  },

  {
   
  id1: 'WEIGHT LOSS PROGRAM',
  id2: '3 Month Program that aims in weight loss through Aasanas,Surya Namaskarams,Pranayama and Diet',
  id3: 'Course Fee INR:500.00 per session',
  img: require('./Pictures/One-On-OneWeightLoss.jpeg') 
  },
 
 

  {
   
    id1: 'AILMENT THERAPY',
    id2: 'Aims to bring relief to ailments like chronic anxiety,neck/shoulder/knee pain,obesity, through Aasanas,Surya Namaskarams,Pranayama and Diet',
    id3: 'Course Fee INR:500.00 per session',
    img: require('./Pictures/One-On-OneAilment.jpeg') 
    },
   

]

 


const MyTabsOneonOneServicesCall = () => {


  const renderItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'left', }}>{item.id1}</Text>
     
      <Image style={{height:378,width:450,marginRight:5}} source={item.img}></Image>
      <Text style={{ fontSize: 14, fontWeight: 'bold',  textAlign: 'left', }}>{item.id2}</Text>
  
      <Text style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left',borderWidth: 3,
    borderColor: "brown",
    borderRadius: 20, }}>{item.id3}</Text>
      
    </View>
  );

  return (
    <ScrollView style ={{backgroundColor: 'bisque'}}>
      <View style={{backgroundcolor:'red',flexDirection:"column",flex:.12}}>
       <Text style={{ fontSize: 18, fontWeight: 'bold',  textAlign: 'center', }}>ONE ON ONE SERVICES</Text>
      <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>SESSIONS ARE CONDUCTED THROUGH ZOOM/STUDIO/AT HOME AS PER CONVENIENCE OF THE LEARNER</Text>
 
      <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>(AILMENT THERAPY SUCH AS POSTURE CORRECTION,NECK/KNEE/SHOULDER/BACK PAIN,ETC ARE OFFERED)</Text>
     <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>(PRENATAL AND POSTNATAL YOGA SESSIONS AVAILABLE)</Text>
      <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>CARE TAKEN FOR STUDENTS WITH AILMENTS</Text>

     
    <Text style={{ fontSize: 15, fontWeight: 'bold',  borderLeftWidth:1,borderRightWidth:1,borderTopWidth:1,borderBottomWidth:1,textAlign:'center',alignContent:'center',justifyContent:'center' }}>
    Timing and location of sessions are decided as per the convenience of the learner 
    </Text>
 
     </View>

 <View style ={{backgroundColor: 'bisque',flexDirection:"column",flex:.88}} >

 <FlatList
   data={data}
   renderItem={renderItem}
   keyExtractor={(item) => item.id1}
   numColumns={2}
 />
 </View>


</ScrollView>
  );
 

  
};

const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 470,
    borderWidth: 2,
    borderColor: '#ddd',
    borderRadius: 8,
  },
});

   // alert('end of testimonials')

  export default MyTabsOneonOneServicesCall;