import axios from 'axios';
import { View, Button,TextInput,StyleSheet,ScrollView,Text,Image } from 'react-native';

import React, { useState } from 'react';
import validator from 'validator';


const styles = StyleSheet.create({
    input: {
      height: 40,
      width:250,
      margin: 12,
      borderWidth: 1,
      borderRadius:10,
      padding: 10,
    },
    inputmultiline: {
      height:100,
      width:250,
      margin: 12,
      borderWidth: 1,
      borderRadius:10,
      padding: 10,
    }
  })
  export default function MyTabsHomeCall()
  {

  const [txtName, setName] = React.useState('');
  const [txtEmail, setEmail] = React.useState('');
  const[txtPhNumber,setPhNumber]=React.useState('');
  const [txtMessage,setMessage]=React.useState('');


  const validatePhoneNumber = () => {
    const phoneNumberRegex = /^\d{10}$/; // Regex pattern for 10-digit phone number
    return phoneNumberRegex.test(txtPhNumber);
  };

  const validateEmail = () => {
    return validator.isEmail(txtEmail);
  };



const handleEmail = async () => {

  if (!validatePhoneNumber()) { 
    alert('Invalid Phone Number', 'Please enter a valid 10-digit phone number.');
    return;
  }
  if (!validateEmail()) {
    alert('Invalid Email', 'Please enter a valid email address.');
    return;
  }
    try {
        const response = await axios.post('http://localhost:3000/send-email', {
        
            to:'l.selvarathi@gmail.com',
            subject:'Enquiry from the Website',
            text:'There is an enquiry from the website.' + '\n\nName:' + txtName + '\n\nPhone Number:' + txtPhNumber + '\n\n Emailid:' + txtEmail + '\n\n Details:' + txtMessage
        });
        console.log(response.data);
        alert('Your Query is sent. We will get back to you soon')
    } catch (error) {
        console.error(error);
 //   alert('sending email failed')
    }
};

   
return (
  <ScrollView style ={{backgroundColor: 'bisque',height:'1000',flex:1}}>
    <View style={{alignContent:'center',justifyContent:'center',flexDirection:'column'}}>
<Text style={{ fontSize: 20, fontWeight: 'bold', flex: 1,  textAlign: 'center' }}>{'\n'}
  </Text>
  
  <Image style={{height:450,width:1400,alignItems:'center',justifyContent:'center',marginLeft:50}} 
   source={require('./Pictures/Ourstory4.jpeg')}/>
<Text style={{ fontSize: 20, flex: 1, fontWeight:'bold',textAlign: 'left', justifyContent:'center',marginLeft:700}}>{'\n'}OUR STORY</Text>
</View>
<View style={{alignContent:'center',justifyContent:'center',flexDirection:'column'}}>
<Text style={{ fontSize: 18, flex: 1,textAlign: 'left', justifyContent:'center',marginLeft:65,marginRight:65}}>
{'\n'}The birth of Sai Siddha Samathi Yogashram happened in 1996 at Kovilpatti after Gurujini Chitra Devi decided to establish one entity to teach, share and spread awareness of Yoga and Naturopathy. After many years of practicing, teaching, and providing therapy for many illnesses under various other institutes across India, Gurujini chose to make Chennai, the base for her Yogashram. The Chennai studio of the Yogashram was started in the year 2008.
Her thought is that a human body does not need anything external, other than what is available in nature to live a fulfilling life. Hence her guiding principle is 'Body in Harmony with Nature'. Being a staunch devotee of Shirdi Sai Baba, with His blessings, Gurujini and her team at the Yogashram has grown with over 2000 students. The Yogashram has 4 branches in Chennai and Kovilpatti, apart from classes being conducted across the globe through the online medium.{'\n'}  </Text>
</View>
   <Image style={{height:300,width:300,alignItems:'center',justifyContent:'center',marginLeft:610}} 
   source={require('./Pictures/GurujiniBright.jpeg')}>

   </Image>
   <Text style={{ fontSize: 20, fontWeight: 'bold', flex: 1, textAlign: 'center', justifyContent:'center'}}>
   {'\n'}FOUNDER-GURUJINI CHITRA DEVI{'\n'}</Text>
   <Text style={{ fontSize: 18, flex: 1, textAlign: 'left', justifyContent:'center',marginLeft:65,marginRight:65}}>
 
{'\n'}Born in the South Indian town of Kovilpatti in Tamil Nadu, India, Chitra Devi showed immense interest in yoga and allied activities from an early age. Seeing her inclination her mother left no stone unturned to ensure her training in sports and martial arts. A national-level weightlifting champion, a black belt holder in karate, a certified personal trainer from the International Sports Science Association, a Master in Tamil, and a Diploma in Naturopathy & Yogic Sciences enabled her to start teaching Yoga across the globe from 1996.


​
{'\n'}
After taking Deeksha from Guruji Shri Lal Maharaj at Uttar Kashi in India, she further developed her skills in Numerology healing, Crystal healing, Pendulum drowsing, Varma, Card reading, Gemology, Aura reading, Lamp therapy, EFT, Hypnotherapy, Massage therapy, Foot, Hand and Face reflexology, and Gua Sha.

Known for her healing touch, calm composure, and divine aura, her skill and abilities have made her a much sought-after yoga and naturopathy Guru around the world.
{'\n'}{'\n'}</Text>
<View style={{backgroundcolor:'bisque',flex:1,flexDirection:"column",alignContent:'center',justifyContent:'center'}}>
<Text style={{ fontSize: 18, fontWeight: 'bold', flex: 1,  textAlign: 'center' }}>CONTACT US{'\n'}</Text>
<Text>{'\n'}{'\n'}</Text>
</View>
<View style={{backgroundcolor:'bisque',flexDirection:"column",alignContent:'center',position:'relative',alignItems:'center',justifyContent:'center'}}>
   <View style={{backgroundcolor:'bisque',flex:1,flexDirection:"column", position:"relative",alignContent:'center',justifyContent:'center'}}>


         <TextInput style={styles.input}   onChangeText={text => setName(text)}
          value={txtName} placeholder="Name" maxLength={35}></TextInput>
    
      
          <TextInput style={styles.input} onChangeText={text => setEmail(text)} keyboardType="email-address" value={txtEmail} placeholder="Email" maxLength={70}></TextInput>
 
          <TextInput style={styles.input} onChangeText={text => setPhNumber(text)} value ={txtPhNumber} placeholder="Phone Number" maxLength={15}></TextInput>
          
    </View>
   
    <View style={{backgroundcolor:'bisque',flex:1,flexDirection:"column", position:"relative",alignContent:'center',justifyContent:'center'}}>


           <TextInput style={styles.inputmultiline}  maxLength={500}
                onChangeText={setMessage}
              value={txtMessage}
               multiline={true}
              
           placeholder="Messsage"
            
                ></TextInput>

     </View>
     </View>
  <View style={{backgroundColor:'bisque',zIndex:3,flex:1,flexDirection:"row",alignContent:'center',justifyContent:'center',borderRadius: 20, overflow: 'hidden'}}>


<Button title='SUBMIT' color='#ff7f50' onPress={handleEmail}  style={{ borderRadius: 20, paddingVertical: 10 }}></Button>

   
  </View>
   </ScrollView>
); 

}




