import { Text, ScrollView,Image,FlatList,View,StyleSheet} from 'react-native';
const data = [
  {
    id: 'L.Selvi Rathi - Yoga Instructor and Coordinator of the Yogashram ',
   img: require('./Pictures/Instructors/RathijiInstructor1.jpeg'),
   desc:'Popularly called as Rathi Mam, Selva Rathi is a M.Phil(English) graduate. She developed interest in Yoga and earned a  MSc degree in Yoga. She has around 16 years of experience in teaching Yoga. An expert in Reflexology,Massage Therapy,Crystal healing,Astology,Gua Sha,Seed Therapy she is also a co-founder and coordinator of the Yogashram.'
  },
  {
    id: 'Manimekalai - Yoga Instructor',
    img: require('./Pictures/Instructors/ManiMekalaiMam1.jpeg'),
    desc:'Manimekali is a Siddha doctor and a Yoga degree holder. Her combined knowledge of herbs and Yoga, helps a student to overcome the limitations in doing asanas',
  },
  {
    id: 'Venkatesh - Yoga Instructor',
    img: require('./Pictures/Instructors/Venkatesh.jpg'),
    desc:'A posture correction expert, Venkatesh is a B.Sc graduate in Yoga. He actively listens to the problems of the students and tries to get into the root of the problem before offering solutions. Popular among students as Venkatesh master, he has an experice of around 16 years in teaching Yoga.'
   },
   {
    id: 'Paramashivan - Yoga Instructor',
    img: require('./Pictures/Instructors/Paramashivan.jpg'),
    desc:'Paramashivan is a BA and DYNS graduate in Yoga and has a rich experience of teaching yoga for 13 years. He is in charge of the Kamarajar Avenue Studio and he is an all rounder in understanding the problem of the student and giving the appropriate work out for them.',
  },
  {
    id: 'L.Selva Mariappan - Yoga Instructor',
    img: require('./Pictures/Instructors/Mari.jpg'),
    desc:'Mari sir holds a B.Sc degree in Electronics. His passion for Yoga turned him to a M.Sc (Yoga) graduate. He is popularly known to be a task master and holds 12 years of experience in teaching yoga.'
  },
  {
    id: 'Shanmuga Sundari - Yoga Instructor',
    img: require('./Pictures/Instructors/SundariInstructor2.jpeg'),
    desc:'Popular amongst students Sundari, she holds a  B.Sc degree in Yoga. Her mere presence in the studio, inspires one towards attempting for a perfect posture. She has around 10 years of experince in teaching yoga.'
  },
   {
    id: 'Nambi Rajan - Yoga Instructor',
    img: require('./Pictures/Instructors/Nambi3.jpg'),
    desc:'Popularly addressed as Nambi sir, Nambi Rajan holds a DYNS and has around 6 years of experience in teaching Yoga. He is an expert in giving yoga classes for the geriatrics at our studio and their homes.'
  },
    
  
  
   {
    id: 'Jayashree - Yoga Instructor',
    img: require('./Pictures/Instructors/JayshreeMam1.jpeg'),
    desc:'Jayashree is a B.Sc graduate in Yoga and known for her presence of mind in understanding the problem of the practioner. She holds 6 years of experience in teaching yoga and she is an acupuncturist.'
  },
  
  // Add other video data objects here
];


const renderItem = ({ item }) => (
  <View style={styles.item}>
    <Text style={{ fontSize: 15, fontWeight: 'bold', flex: 1, textAlign: 'left', }}>{item.id}</Text>
   
    <Image style={{height:165,width:155,marginRight:5}} source={item.img}></Image>
    <Text style={{ fontSize: 14, fontWeight: 'bold', flex: 1, textAlign: 'left', }}>{item.desc}</Text>
   
  </View>
);

function MyTabsTeamCall() {
    //  alert('inside team')
    
        
      
  return (
    <ScrollView style ={{backgroundColor: 'bisque'}}>
      <Text  style={{ fontSize: 18, fontWeight: 'bold', flex: 1, textAlign: 'center', }}>{'\n'}THE TEAM{'\n'}{'\n'}</Text>
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      numColumns={2}
    />
    </ScrollView>
  );
};
     // alert('end of team')
    
    
    
    
      const handleModal = () => 
      {
        setIsModalVisible(() => !isModalVisible);
       alert('inside handlemodal')
        return (
          <View style={{ flex: 1 }}>
            <Modal>
          <Text>Hello!</Text>
          
    
    <Text style={{ fontSize: 17,  flex: 1,marginLeft:5,borderWidth:2,borderColor:'aliceblue',height:150, width:300}}>{'\n'}{'\n'}Foot reflexology is the
     science of activating the vital pressure point on the foot by gentle strokes. 
     It helps in relieving muscles stress and aids in sleeping.
    </Text>
    <Button title='Hide Modal' ></Button>
      
    </Modal>
    
       </View>
        )
      }

      

const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 250,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
  },
});
      export default MyTabsTeamCall;
    