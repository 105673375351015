import React from 'react';
import { View, FlatList, Text, StyleSheet } from 'react-native';

const data = [
  {
    id: 'CHENNAI - GANDHI NAGAR',
    address1: "Anand Raj Villa, 5/7,2nd Canal Cross Road,Gandhi Nagar, Adyar Chennai-600020(Near BVM school) ",
    map1:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.4180329762894!2d80.24691437484206!3d13.009030187309811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52673fe11cef35%3A0xa6b29e946ac5eab2!2sSai%20siddha%20samathi%20yoghashram!5e0!3m2!1sen!2sin!4v1705553215392!5m2!1sen!2sin',
  },
  { id: 'CHENNAI - KAMARAJ AVENUE', address1: '7/3,Kamaraj Avenue 1st Street,Venkatarathinam Nagar,Adyar,Chennai  - 600020(Near Bharath School)', map1:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.125812241684!2d80.2533231!3d13.0017926!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267233230b32f%3A0xbe245ed561e699b3!2sSai%20Siddha%20Samathi%20Yogashram!5e0!3m2!1sen!2sin!4v1706178520756!5m2!1sen!2sin'},
  { id: 'CHENNAI - NANGANALLUR', address1: 'No:9,9th Street,Lakshmi Nagar,Nanganallur,Chennai-600061(Opp Vasanth Apts)',map1:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15551.74723041994!2d80.1877929!3d12.9758936!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525e08046ccd17%3A0x330ceb5982f91f6e!2sSai%20Siddha%20Samathi%20Yogashram!5e0!3m2!1sen!2sin!4v1706180437942!5m2!1sen!2sin'},
  { id: 'SOUTH TAMILNADU - KOVILPATTI', address1: '60/C, 1/1, Katheeresan Koil street, Park East Street,Kovilpatti - 628501 ( Near New Municipality Office)',map1:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d984.7060894364462!2d77.86223376450315!3d9.170311730028743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06b34b379d12e7%3A0x10d2e703bea25c6f!2sSai%20Siddha%20Samathi%20Yogashram%20and%20service%20apartments!5e0!3m2!1sen!2sin!4v1706185428933!5m2!1sen!2sin'}

 // Add more dummy data as needed
];

const GridExample = () => {
  const renderItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={{ fontSize: 15, fontWeight: 'bold', flex: 1, textAlign: 'center', }}>{item.id}</Text>
      <Text style={{ fontSize: 14, fontWeight: 'bold', flex: 1, textAlign: 'center', }}>{item.address1}</Text>
      <iframe
        src={item.map1}
        width="400"
        height="350"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      />
    </View>
  );

  return (
    <View style ={{backgroundColor: 'bisque',height:1250}}>
      <Text  style={{ fontSize: 18, fontWeight: 'bold', flex: 1, textAlign: 'center', }}>{'\n'}STUDIO LOCATIONS{'\n'}{'\n'}</Text>
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      numColumns={2}
    />
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 500,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
  },
});

export default GridExample;