
import { Text, ScrollView,View,Image,FlatList,StyleSheet } from 'react-native';

const data = [
  {
    
    id1: 'BEGINNER COURSE-1 MONTH(Group Class)',
    id2: 'Aims to bring flexibility and trains towards Surya Namaskaram and Pranayama',
    id3: 'Course Fee INR:2000.00',
    img:require('./Pictures/Beginner-Offline.png')
  
  }, 
  {
    
    id1: 'FOLLOW UP CLASSES',
    id2: 'Surya Namaskaram,,Pranayama and Aasanas',
    id3: 'Course Fee INR:1600.00 per month',
    img:require('./Pictures/studio grp class.jpg')
  
  },
  {
    
    id1: 'WEIGHT LOSS PROGRAM',
    id2: '3 Month Program that aims in weight loss through asanas,Surya Namaskarams,Pranayama and Diet',
    id3: 'Course Fee INR:2000.00 per month',
    img:require('./Pictures/Offline-WeightLoss.jpeg')
  
  },

]




const renderItem = ({ item }) => (
  <View style={styles.item}>
    <Text style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'left', }}>{item.id1}</Text>
   
    <Image style={{height:325,width:450,marginRight:5}} source={item.img}></Image>
    <Text style={{ fontSize: 14, fontWeight: 'bold',  textAlign: 'left', }}>{item.id2}</Text>

    <Text style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left',borderWidth: 3,
    borderColor: "brown",
    borderRadius: 20, }}>{item.id3}</Text>
  </View>
);
function MyTabsOfflineServicesCall() {
    // alert('inside Offline Services')
    return (
      <ScrollView style ={{backgroundColor: 'bisque'}}>
        <View style={{backgroundcolor:'red',flexDirection:"column",flex:.2}}>
         <Text style={{ fontSize: 18, fontWeight: 'bold',  textAlign: 'center', }}>OFFLINE SERVICES(AT OUR STUDIOS)</Text>
       
        <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>(AILMENT THERAPY SUCH AS POSTURE CORRECTION,NECK/KNEE/SHOULDER/BACK PAIN,ETC ARE OFFERED)</Text>
       <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>(PRENATAL AND POSTNATAL YOGA SESSIONS AVAILABLE)</Text>
        <Text style={{ fontSize: 15, fontWeight: 'bold',  textAlign: 'center', }}>CARE TAKEN FOR STUDENTS WITH AILMENTS</Text>
  
       
      <Text style={{ fontSize: 15, fontWeight: 'bold',  borderLeftWidth:1,borderRightWidth:1,borderTopWidth:1,borderBottomWidth:1 }}>
       {'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}All Sessions are available at the following timings(Session duration-1 hour) : 
       {'\n'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}Monday-Friday{'\t'}{'\t'}{'\t'}{'\t'}Saturdays{'\n'}
      {'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}------------------{'\t'}{'\t'}{'\t'}{'\t'}------------{'\n'}
       {'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}5am-11am IST{'\t'}{'\t'}{'\t'}{'\t'}5am-9am IST {'\n'}
       {'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}{'\t'}5pm-7pm IST{'\t'}{'\t'}{'\t'}
       {'\t'}{'\t'}Evening classes not available on Saturdays</Text>
   
       </View>
  
   <View style ={{backgroundColor: 'bisque',flexDirection:"column",flex:.8}} >
 
   <FlatList
     data={data}
     renderItem={renderItem}
     keyExtractor={(item) => item.id}
     numColumns={2}
   />
   </View>


  </ScrollView>
    );
   
  // alert('end of Ofline')
   }

   

const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 400,
    width:450,
    borderWidth: 2,
    borderColor: '#ddd',
    borderRadius: 8,
  },
});
   export default MyTabsOfflineServicesCall;